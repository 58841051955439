<template>
  <div>
    <div :class="{ vipList: vipList.length > 0 }">
      <div class="vipItem" v-for="(item, index) in vipList" :key="index">
        <div class="itemBox">
          <div class="df_Yc">
            <div class="Icon mr8" :class="{ Icon1: item.author_uid }"></div>
            <p class="f0404 f_b f16">{{ item.title }}</p>
          </div>
          <div class="openVip" v-if="!item.author_uid" @click="goLink()">
            立即续费
          </div>
          <div class="openVip" v-else @click="goLink(item.author_uid)">
            前往主页
          </div>
        </div>
        <p class="ml_11 mt10 c_66 f14 f_b">有效期：{{ item.time_range }}</p>
        <p class="info" v-show="item.title == '课程VIP会员' && item.user_agent == 3">
          * 知设网会员体系全新的改版升级，为答谢您对知设网的支持，现已将您<span
            >“景观大数据”年度终极会员升级为知设网课程VIP会员，届时您可以享受全站VIP课程不限次数学习</span
          >
        </p>
        <p class="info" v-show="item.title == '素材VIP会员' && item.user_agent == 3">
          * 知设网会员体系全新的改版升级，为答谢您对知设网的支持，现已将您<span
            >“景观大数据”年度终极会员升级为知设网素材VIP会员，届时您可以享受全站VIP素材免费下载</span
          >
        </p>
        <p class="info" v-show="item.title == '素材VIP会员' && item.user_agent == 4">
          * 知设网会员体系全新的改版升级，为答谢您对知设网的支持，现已将您<span
            >“景观大数据”年度素材VIP会员升级为知设网素材VIP会员，届时您可以享受全站VIP素材免费下载</span
          >
        </p>
        <p class="info" v-show="item.title == '“景观大数据”助力学年度会员'">
          *
          知设网会员体系全新的改版升级，目前此会员已暂无销售，为了保证您的权益，此会员有效期内<span
            >不会对您已购买的会员权益和期限造成任何影响，可照常使用，景观大数据2021年之前的课程免费学习。</span
          >
        </p>
        <p class="info" v-show="item.title == '“景观大数据”终极终身会员'">
          * 知设网会员体系全新的改版升级，目前此会员已暂无销售，但
          <span
            >不会对您的“景观大数据”终身会员权益造成任何影响，可照常使用。</span
          >
        </p>
        <p class="info" v-show="item.title == '“软件新管家”终极终身会员' || item.title == '“软件新管家”年度终极会员' || item.title == '“软件新管家”月度会员'">
          *
          知设网会员体系全新的改版升级，目前此会员已暂无销售，为了保证您的权益，此会员有效期内<span
            >不会对您已购买的会员权益和期限造成任何影响，可照常使用。</span
          >
        </p>
      </div>
    </div>
    <div class="myVipCont">
      <div class="contItem">
        <div class="df_Yc">
          <p class="itemTitle">素材VIP特权</p>
          <div class="df_Yc">
            <div class="buttonDiv_2" v-if="vipStatus.is_library_vip == 0" @click="goVip(2)">
              未点亮
            </div>
            <div class="buttonDiv_2" v-else>已点亮</div>
          </div>
        </div>
        <div class="vipIconList" v-if="vipStatus.is_library_vip == 0">
          <div class="iconItemBox">
            <img src="@/assets/image/myVipImg/material-icon/SU1.png" />
<!--            <p>VIP区免费</p>-->
            <p>SU模型</p>
          </div>
          <div class="iconItemBox">
            <img src="@/assets/image/myVipImg/material-icon/3D1.png" />
<!--            <p>VIP区免费</p>-->
            <p>3D模型</p>
          </div>
          <div class="iconItemBox">
            <img src="@/assets/image/myVipImg/material-icon/PS1.png" />
<!--            <p>VIP区免费</p>-->
            <p>PS素材</p>
          </div>
          <div class="iconItemBox">
            <img src="@/assets/image/myVipImg/material-icon/MATERIAL1.png" />
<!--            <p>VIP区免费</p>-->
            <p>素材大全</p>
          </div>
          <div class="iconItemBox">
            <img src="@/assets/image/myVipImg/material-icon/TIE1.png" />
<!--            <p>VIP区免费</p>-->
            <p>高清贴图</p>
          </div>
          <div class="iconItemBox">
            <img src="@/assets/image/myVipImg/material-icon/TEXT1.png" />
<!--            <p>VIP区免费</p>-->
            <p>方案文本</p>
          </div>
          <div class="iconItemBox">
            <img src="@/assets/image/myVipImg/material-icon/BOOK1.png" />
<!--            <p>VIP区免费</p>-->
            <p>设计书籍</p>
          </div>
          <div class="iconItemBox">
            <img src="@/assets/image/myVipImg/material-icon/MATCH1.png" />
<!--            <p>VIP区免费</p>-->
            <p>比赛合集</p>
          </div>
          <div class="iconItemBox">
            <img src="@/assets/image/myVipImg/material-icon/PHOTO1.png" />
<!--            <p>VIP区免费</p>-->
            <p>设计图库</p>
          </div>
        </div>
        <div class="vipIconList" v-else>
          <div class="iconItemBox">
            <img src="@/assets/image/myVipImg/material-icon/SU.png" />
<!--            <p>VIP区免费</p>-->
            <p>SU模型</p>
          </div>
          <div class="iconItemBox">
            <img src="@/assets/image/myVipImg/material-icon/3D.png" />
<!--            <p>VIP区免费</p>-->
            <p>3D模型</p>
          </div>
          <div class="iconItemBox">
            <img src="@/assets/image/myVipImg/material-icon/PS.png" />
<!--            <p>VIP区免费</p>-->
            <p>PS素材</p>
          </div>
          <div class="iconItemBox">
            <img src="@/assets/image/myVipImg/material-icon/MATERIAL.png" />
<!--            <p>VIP区免费</p>-->
            <p>素材大全</p>
          </div>
          <div class="iconItemBox">
            <img src="@/assets/image/myVipImg/material-icon/TIE.png" />
<!--            <p>VIP区免费</p>-->
            <p>高清贴图</p>
          </div>
          <div class="iconItemBox">
            <img src="@/assets/image/myVipImg/material-icon/TEXT.png" />
<!--            <p>VIP区免费</p>-->
            <p>方案文本</p>
          </div>
          <div class="iconItemBox">
            <img src="@/assets/image/myVipImg/material-icon/BOOK.png" />
<!--            <p>VIP区免费</p>-->
            <p>设计书籍</p>
          </div>
          <div class="iconItemBox">
            <img src="@/assets/image/myVipImg/material-icon/MATCH.png" />
<!--            <p>VIP区免费</p>-->
            <p>比赛合集</p>
          </div>
          <div class="iconItemBox">
            <img src="@/assets/image/myVipImg/material-icon/PHOTO.png" />
<!--            <p>VIP区免费</p>-->
            <p>设计图库</p>
          </div>
        </div>
      </div>
      <div class="contItem">
        <div class="df_Yc">
          <p class="itemTitle">课程VIP特权</p>
          <div class="df_Yc">
            <div class="buttonDiv_2" v-if="vipStatus.is_class_vip == 0"  @click="goVip(1)">
              未点亮
            </div>
            <div class="buttonDiv_2" v-else>已点亮</div>
          </div>
        </div>

        <div class="vipIconList" v-if="vipStatus.is_class_vip == 0">
          <div class="iconItemBox1">
            <img src="@/assets/image/myVipImg/course-icon/VIDEO1.png" />
            <p>原创教程</p>
          </div>
          <div class="iconItemBox1">
            <img src="@/assets/image/myVipImg/course-icon/FILE1.png" />
            <p>配套资源</p>
          </div>
          <div class="iconItemBox1">
            <img src="@/assets/image/myVipImg/course-icon/COULD1.png" />
            <p>一键缓存</p>
          </div>
          <div class="iconItemBox1">
            <img src="@/assets/image/myVipImg/course-icon/KF1.png" />
            <p>专属客服</p>
          </div>
          <div class="iconItemBox1">
            <img src="@/assets/image/myVipImg/course-icon/BOOK1.png" />
            <p>作业评改</p>
          </div>
          <div class="iconItemBox1">
            <img src="@/assets/image/myVipImg/course-icon/VIP1.png" />
            <p>专属标识</p>
          </div>
        </div>
        <div class="vipIconList" v-else>
          <div class="iconItemBox1">
            <img src="@/assets/image/myVipImg/course-icon/VIDEO.png" />
            <p>原创教程</p>
          </div>
          <div class="iconItemBox1">
            <img src="@/assets/image/myVipImg/course-icon/FILE.png" />
            <p>配套资源</p>
          </div>
          <div class="iconItemBox1">
            <img src="@/assets/image/myVipImg/course-icon/COULD.png" />
            <p>一键缓存</p>
          </div>
          <div class="iconItemBox1">
            <img src="@/assets/image/myVipImg/course-icon/KF.png" />
            <p>专属客服</p>
          </div>
          <div class="iconItemBox1">
            <img src="@/assets/image/myVipImg/course-icon/BOOK.png" />
            <p>作业评改</p>
          </div>
          <div class="iconItemBox1">
            <img src="@/assets/image/myVipImg/course-icon/VIP.png" />
            <p>专属标识</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getToken } from "../../utils/auth";
import { myVipCenter, userIsVip } from "../../Api";
export default {
  name: "MyVip",
  data() {
    return {
      vipList: [],
      vipStatus: {},
    };
  },
  created() {
    myVipCenter(getToken()).then((res) => {
      this.vipList = res.data;
    });
    userIsVip(getToken()).then((res) => {
      this.vipStatus = res.data;
    });
  },
  methods: {
    goLink(id) {
      if (id) {
        const { href } = this.$router.resolve({
          path: "/user/index",
          query: { uid: id },
        });
        window.open(href, "_blank");
      } else {
        const { href } = this.$router.resolve({
          path: "/vip/material",
          query: { uid: id },
        });
        window.open(href, "_blank");
      }
    },
    goVip(type) {
      if (type == 1) {
        const { href } = this.$router.resolve({
          path: "/vip/course",
        });
        window.open(href, "_blank");
      } else {
        const { href } = this.$router.resolve({
          path: "/vip/material",
        });
        window.open(href, "_blank");
      }
    },
  },
};
</script>

<style scoped>
.vipIconList {
  margin-bottom: 0.6rem;
  margin-right: -0.4rem;
}
.vipIconList:after {
  content: "";
  visibility: hidden;
  width: 0;
  height: 0;
  display: block;
  clear: both;
}
.iconItemBox {
  margin-top: 0.4rem;
  text-align: center;
  float: left;
  margin-right: 0.4rem;
}
.iconItemBox img {
  width: 0.6rem;
  height: 0.68rem;
  margin-bottom: 0.1rem;
}
.iconItemBox p {
  color: #bfbfbf;
  font-size: 0.14rem;
  font-weight: bold;
  text-align: center;
}
.iconItemBox1 {
  width: 0.9rem;
  text-align: center;
  float: left;
  margin-right: 0.4rem;
}
.iconItemBox1 img {
  width: 0.9rem;
  height: 0.9rem;
  margin-bottom: 0.1rem;
}
.iconItemBox1 p {
  width: 0.9rem;
  color: #bfbfbf;
  font-size: 0.14rem;
  font-weight: bold;
  text-align: center;
}
.buttonDiv {
  cursor: pointer;
  margin-right: 0.3rem;
  padding: 0.04rem 0.2rem;
  color: #ffffff;
  font-size: 0.12rem;
  background: #d9d9d9;
  border-radius: 0.02rem;
}
.buttonDiv_2 {
  cursor: pointer;
  margin-right: 0.3rem;
  padding: 0.04rem 0.2rem;
  color: #ffffff;
  font-size: 0.12rem;
  background: #cf9663;
  border-radius: 0.02rem;
}
.itemTitle {
  font-size: 0.2rem;
  color: #040404;
  font-weight: bold;
  margin-right: 0.3rem;
}
.myVipCont {
  background: #ffffff;
  padding: 0.2rem 0.3rem;
  border-radius: 0.04rem;
}
.vipItem {
  background: #ffffff;
  padding: 0.2rem 0.3rem;
  border-radius: 0.04rem;
  margin-bottom: 0.1rem;
}

.itemBox {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.vipList {
  margin-bottom: 0.2rem;
}
.ml_11 {
  margin-left: 0.16rem;
}
.Icon {
  width: 0.08rem;
  height: 0.08rem;
  background: #ff9e58;
  box-shadow: 0px 0px 0.04rem #ff9e58;
  border-radius: 50%;
}
.Icon1 {
  width: 0.08rem;
  height: 0.08rem;
  background: #8486f8;
  box-shadow: 0px 0px 0.04rem #8486f8;
  border-radius: 50%;
}
.openVip {
  width: 1.4rem;
  height: 0.36rem;
  line-height: 0.36rem;
  text-align: center;
  border-radius: 0.04rem;
  background: #f46600;
  color: #ffffff;
  font-size: 0.14rem;
  cursor: pointer;
  font-weight: bold;
}
.info {
  margin-top: 0.1rem;
  margin-left: 0.16rem;
  font-size: 0.12rem;
  font-family: PingFang SC;
  font-weight: 400;
  color: #999999;
  opacity: 1;
}

.info span {
  font-weight: bold;
  color: #040404;
}
</style>